@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~@fortawesome/fontawesome-free/css/all.min.css';


@font-face {
  font-family: 'Evenings';
  src: url('./assets/fonts/Evenings-KVZoZ.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'bodoni';
  src: url('./assets/fonts/BodoniflfRoman-vmAD.ttf') format('truetype');
}

@font-face {
  font-family: 'didot';
  src: url('./assets/fonts/Didot-w5Rx.otf') format('truetype');
}

@font-face {
  font-family: 'garogier';
  src: url('./assets/fonts/GarogierRegular-ZymB.ttf');
}
body {
  @apply font-evenings;
  font-family: 'Evenings', Arial, sans-serif; /* Add Arial for fallback */
}