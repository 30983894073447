.home-page{
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.1)
  ),
  url('../assets/images/darkbg.png'); /* Replace with actual image path */
background-position: center;
background-size: cover;
background-repeat: no-repeat;
background-attachment: fixed; /* Adjust in media queries */
z-index: 1; /* Ensure it doesn't overlap */

}


  .home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: black;
    
  }
  
  .carousel-wrapper {
    background-color: transparent;
  }
  
/* Hero section background */
.hero-section {
  position: relative;
  height: calc(var(--vh, 1vh) * 95); /* 95vh using CSS variable */
  width: 100%;
  display: flex; /* Use flexbox for centering */
  align-items: center;
  justify-content: center;
  

}


.intro-text{
  position: relative;
  padding-bottom: 8vh;
  max-width: 90%;
}


.intro-text h1{
  color: white;
  text-shadow: 2px 2px 1px var(--pink-light);
    text-shadow: 2px 2px 2px #E7D6BA;
    letter-spacing: 2px;
    white-space: nowrap; /* Prevent wrapping */
}

/* =========================================
   Bobbing Animation Keyframes
========================================= */
@keyframes bobbing {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* =========================================
   Letter Styles for Bobbing Animation
========================================= */
.intro-text .letter {
  display: inline-block;
  animation: bobbing 3s ease-in-out infinite;
  /* Optional: Adding random delays for a more organic effect */
}

/* =========================================
   Space Span Styles
========================================= */
.intro-text .letter.space {
  /* Remove animation */
  animation: none;
  
  /* Optional: Add fixed width to maintain spacing */
  display: inline-block;
  width: 0.5em; /* Adjust as needed */
  
  /* Ensure the space is preserved */
  white-space: pre;
}

/* =========================================
   Optional: Hover Effect on Letters
========================================= */
.intro-text .letter:hover {
  color: #ff69b4; /* HotPink */
  text-shadow: 2px 2px 5px rgba(255, 105, 180, 0.7);
}


.intro-text p{
  background-color: rgba(0, 0, 0, 0.096); /* A semi-transparent background to enhance the effect */
  text-shadow: 2px 2px 5px black;
}

.intro-text span{
  color: rgb(255, 255, 255);
}

/* Ensure the hero content stays centered and above markers */
.bg-opacity50 {
  position: relative; /* Set to relative so z-index can take effect */
  z-index: 0; /* Ensure this stays above the markers */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%; /* Full height to center vertically */
}

/* Marker animations */
.order-marker {
  position: absolute;
  width: 30px; /* Adjust the size of the marker */
  height: auto;
  animation: marker-pop 3s ease-in-out forwards; /* 3-second animation */
  pointer-events: none; /* Prevent interaction with the markers */
  z-index: 1; /* Make sure markers are behind the hero content */
}

@keyframes marker-pop {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
}


/* Ensure content sits above the gradient */
.hero-section > * {
  position: relative;
  z-index: 2;
  width: 100%;
}


.order-marker {
  position: absolute;
  width: 30px; /* Adjust the size of the marker */
  height: auto;
  animation: marker-pop 3s ease-in-out forwards; /* 3-second animation */
  pointer-events: none; /* Prevent interaction with the markers */
}
  

  .hero-overlay {
    background-color: rgba(48, 43, 40, 0.8);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--pink-light);
  }
  

  
  .hero-description {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .cta-buttons {
    display: flex;
    gap: 15px;
    
  }
  
  .cta-btn {
    background-color: var(--red-main);
    color: var(--pink-light);
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .cta-btn:hover {
    background-color: var(--deep-red);
  }
  
  /* Styling for the cupcake images */
.cupcake-image {
  position: absolute;
  width: 100px; /* Set the size of the cupcakes */
  height: auto;
  z-index: 3; /* Ensure the cupcakes are on top of the hero content */
}

/* Position the first cupcake on the left */
.cupcake-left {
  top: 20%;
  left: 30%;
}

/* Position the second cupcake on the right */
.cupcake-right {
  top: 25%;
  right: 10%;
}
  
  .reviews-section {
    background-color: var(--red-dark);
    color: var(--beige);
    padding: 40px 20px;
    text-align: center;
  }
  
  .review-card {
    background-color: var(--brown-medium);
    padding: 20px;
    margin: 20px;
    border-radius: 5px;
  }
  
  .cta-section {
    background-color: var(--brown-dark);
    padding: 50px;
    color: var(--pink-light);
    text-align: center;
  }
  
  .cta-section h3 {
    margin-bottom: 20px;
  }

  /* Media query for smaller devices (tablets and below) */
/* Media query for smaller devices (tablets and below) */
@media (max-width: 768px) {
  .home-page {
    background-attachment: scroll; /* Prevent fixed behavior on mobile */
    background-size: cover; /* Ensure it still covers the area */
    background-position: center top; /* Align the image for better fit */
    max-height: 100vh; /* Maintain viewport height */
  }

  .hero-section {
    height: calc(var(--vh, 1vh) * 90); /* 90vh on smaller screens */
  }

  .intro-text h1 {
    font-size: 3.5rem; /* Reduce font size for h1 */
  }

  .intro-text p {
    font-size: 1.2rem; /* Reduce font size for paragraph */
  }

  .cta-btn {
    font-size: 0.9rem; /* Reduce button font size */
    padding: 10px 20px;
  }

  .cupcake-image {
    width: 70px; /* Reduce size */
  }

  .cupcake-left {
    top: 15%;
    left: 20%;
  }

  .cupcake-right {
    top: 20%;
    right: 15%;
  }
}

@media (max-width: 480px) {
  .home-page {
    background-attachment: scroll; /* Prevent fixed behavior on mobile */
    background-size: cover; /* Ensure it still covers the area */
    background-position: center top; /* Align the image for better fit */
    max-height: 100vh; /* Maintain viewport height */
  }
    
  .hero-section {
    height: calc(var(--vh, 1vh) * 90);
    background-size: contain;
  }

  .intro-text h1 {
    font-size: 2.5rem; /* Further reduce font size for h1 */
  }

  .intro-text p {
    font-size: 1rem;
  }

  .cta-btn {
    font-size: 0.8rem;
    padding: 8px 16px;
  }

  .cupcake-image {
    width: 50px; /* Further reduce size */
  }

  .cupcake-left {
    top: 10%;
    left: 10%;
  }

  .cupcake-right {
    top: 15%;
    right: 10%;
  }
}