/* src/styles/Checkout.css */

/* Container for the entire page */
.checkout-page {
  display: flex;
  justify-content: center;
  padding: 50px 20px;
  background-color: #f9f9f9;
}

/* Main container */
.checkout-container {
  display: flex;
  max-width: 1200px;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
}

/* Shipping details section */
.shipping-details {
  flex: 1;
  font-family: 'Courier New', Courier, monospace;
  padding: 20px;
  background-color: #fff5e6;
  border-right: 1px solid #e0c1a0;
}

.shipping-details h3 {
  color: #4d3319;
  margin-bottom: 55px;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.shipping-details h1{
  margin-bottom: 55px;
  font-weight: 100;
  font-size: 1.8rem;
  padding-right: 50px;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.shipping-details p {
  color: #666;
  font-size: 1.2rem;
  line-height: 1.5;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

/* Checkout content section */
.checkout-content {
  flex: 2;
  padding: 20px;
}

.checkout-content h2 {
  text-align: center;
  color: #4d3319;
  margin-bottom: 20px;
}

/* Adjust form styles as needed */
.checkout-form input {
  font-family:Arial, Helvetica, sans-serif;
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #e0c1a0;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #fff;
}

.checkout-form h3 {
  margin-bottom: 15px;
  color: #4d3319;
}

.card-element {
  padding: 12px;
  height: 45px;
  background-color: #fff;
  border: 1px solid #e0c1a0;
  border-radius: 4px;
  box-sizing: border-box;
  z-index: 2; /* Ensure it's higher than the autofill overlay */
}
/* src/styles/Checkout.css */

.price-summary {
  margin: 20px 0;
}

.price-summary h3,
.price-summary h2 {
  margin: 5px 0;
}

.submit-button{
  margin-top: 10px;
  z-index: 1000;
  color: white;
  padding: 20px;
  padding-right: 30px;
  padding-left: 30px;
  font-size: 1.5rem;
  border: black 2px solid;
  margin-top: 5%;
  margin-left: 2%;
  border-radius: 20px;
  background-color: rgb(139, 40, 40);
}

.submit-button:hover{
  background: #000000;
  color: rgb(139, 40, 40);
  transition: ease all 0.6s ;
}

.error-message {
  color: red;
  font-weight: bold;
  font-size: 1rem;
  margin-top: 8px;
  position: absolute;
  z-index: 1000;
}

.error-actions {
  margin-top: 10px;
}

.error-actions button {
  background-color: #ff4d4f;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
}

.error-actions button:hover {
  background-color: #ff7875;
}

.disabled-button {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Responsive design */
@media (max-width: 768px) {

  .checkout-container {
    flex-direction: column;
  }

  .shipping-details {
    border-right: none;
    border-bottom: 1px solid #e0c1a0;
  }
}