/* src/styles/Toast.css */
.Toastify__toast {
    background-color: #000000 !important; /* Green background */
    color: white !important;
    font-family: 'YourFont', sans-serif;
    top: 10vh;
  }
  
  .Toastify__toast--success {
    border-left: 5px solid #4BB543;
  }
  
  .Toastify__toast--error {
    border-left: 5px solid #FF0000;
  }