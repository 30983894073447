/* src/styles/FAQs/FAQs.module.css */

.faqSection {
  padding: 60px 20px;
  background-color: #f9f9f9;
}

.sectionTitle {
  font-size: 2.5rem;
  color: #333;
  text-align: center;
  margin-bottom: 40px;
}

.faqContainer {
  max-width: 800px;
  margin: 0 auto;
}

.category {
  margin-bottom: 30px;
}

.categoryTitle {
  font-size: 1.8rem;
  color: #db463f; /* Highlight the category titles with a distinct color */
  margin-bottom: 20px;
  border-bottom: 2px solid #db463f;
  padding-bottom: 5px;
  text-align: left;
}

.faqItem {
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 15px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 20px;
}

.question h4 {
  font-size: 1.2rem;
  color: #333;
  margin: 0;
}

.answer {
  max-height: 0;
  overflow: hidden;
  padding: 0 20px;
}

.answer p {
  font-size: 1rem;
  color: #555;
  margin: 0;
  padding: 10px 0;
  font-family: 'Times New Roman', Times, serif;
}

.active .answer {
  max-height: 500px; /* Large enough to accommodate the content */
}

.active .question h4 {
  color: #db463f;
}

.question svg {
  font-size: 1.2rem;
  color: #666;
}

.active .question svg {
  transform: rotate(180deg);
  color: #db463f;
}

@media (max-width: 768px) {
  .faqSection {
      padding: 40px 15px;
  }

  .sectionTitle {
      font-size: 2rem;
  }

  .question h4 {
      font-size: 1rem;
  }

  .answer p {
      font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .faqSection {
      padding: 30px 10px;
  }

  .sectionTitle {
      font-size: 1.8rem;
  }

  .categoryTitle {
      font-size: 1.5rem;
  }

  .question h4 {
      font-size: 0.9rem;
  }

  .answer p {
      font-size: 0.8rem;
  }
}
