/* src/styles/MeetTheBaker/AboutSection.css */

.about-section {
  padding: 50px 20px;
  background-color: #302B28;
}

.about-content {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  gap: 40px;
  flex-wrap: wrap;
}

.about-image {
  flex: 1 1 300px;
  max-width: 450px;
  margin-right: 10%;
  border-radius: 5px;
  object-fit: cover;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.815);
  border: 2px solid rgb(0, 0, 0);
}

.about-text {
  flex: 2 1 500px;
}

.about-text h2 {
  color: hsl(0, 0%, 100%);
  margin-bottom: 20px;
  font-size: 3.0rem;
}

.about-text p {
  font-size: 1.4rem;
  color: #ffffff;
  line-height: 1.6;
  margin-bottom: 15px;
}

/* Responsive Styles for Tablets and Smaller */
@media (max-width: 768px) {
  .about-section {
    padding: 30px 10px; /* Further reduce padding */
  }

  .about-content {
    flex-direction: column;
    text-align: center;
    gap: 20px; /* Reduce gap for smaller screens */
  }

  .about-image {
    max-width: 450px; /* Reduce image size */
    margin-right: 0;
  }

  .about-text h2 {
    font-size: 2.2rem; /* Reduce heading font size */
  }

  .about-text p {
    font-size: 1.1rem; /* Reduce paragraph font size */
  }
}

/* Extra Small Devices (Phones, less than 480px) */
@media (max-width: 480px) {
  .about-section {
    padding: 20px 5px; /* Reduce padding for smaller devices */
  }

  .about-image {
    max-width: 450px; /* Further reduce image size */
  }

  .about-text h2 {
    font-size: 2rem; /* Further reduce heading font size */
  }

  .about-text p {
    font-size: 1rem; /* Further reduce paragraph font size */
  }
}