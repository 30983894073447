:root {
    --color-primary: #DB463F;
    --color-secondary: #A82424;
    --color-accent: #771111;
    --color-background: #E7D6BA;
    --color-text: #302B28;
    
    /* Additional variables for convenience */
    --color-light: #ffffff;
    --color-dark: #000000;
  }

  /* Global Styles for Smooth Scrolling */
html {
    scroll-behavior: smooth;
  }