/* src/styles/ProductsPage.css */

/* Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Products Page */
.products-page {
  width: 100%;
  background-color: #302B28;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.1)
  ),
  url('../assets/images/FALLBANNER.png'); /* Ensure the path is correct */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed; /* Adjust in media queries */
}

/* Page Title */
.products-title {
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
  font-size: 3.5rem;
}

/* Products Section */
.products-section {
  width: 100%;
}

/* Products Container */
.products-container {
  max-width: 1800px; /* Match the product-grid max-width */
  margin: 0 auto; /* Center the container */
}

/* Category Tabs */
.category-tabs {
  display: flex;
  justify-content: center; /* Center the tabs */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

/* Tab Button styles */
.tab-button {
  background-color: #E7D6BA;
  border: 1px solid #000000;
  border-bottom: none;
  padding: 30px 60px;
  cursor: pointer;
  font-size: 1.5rem;
  z-index: 1000;
  color: #4d3319;
  margin: 0 5px; /* Overlap borders */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
  top: 1px;
  transition: background-color 0.3s, color 0.3s;
}

.tab-button:hover {
  background-color: #DB463F;
  color: #fff;
}

.tab-button.active {
  background-color: #000000;
  color: #ffffff;
  font-weight: bold;
  border-bottom: 1px solid #fff;
}

/* Product Grid */
.product-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center; /* Center the grid items */
  margin: 0 auto;
  max-width: 1800px;
  padding: 50px;
  background-color: #E7D6BA;
  border: 1px solid #ccc;
  border-top: none; /* Remove top border to connect with active tab */
}

/* Product Card */  
.product-card {
  flex: 0 1 calc(33.333% - 40px); /* Three columns layout */
  max-width: calc(33.333% - 40px);
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .product-card {
    flex: 0 1 calc(50% - 20px); /* Two columns on tablets */
    max-width: calc(50% - 20px);
  }

  .category-tabs {
    flex-wrap: wrap;
    justify-content: center;
  }

  .tab-button {
    margin-bottom: -1px;
    margin-right: 0;
  }
}

@media (max-width: 480px) {
  .product-card {
    flex: 0 1 100%; /* One column on mobile devices */
    max-width: 100%;
  }
}

/* "How to Order" Section Styles */
.how-to-order {
  background-color: #E7D6BA;
  padding: 60px 20px;
  text-align: center;
  margin-bottom: 5%;
}

.how-to-order h2 {
  font-size: 2.5rem;
  color: #000000;
  margin-bottom: 40px;
}

.order-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.order-text {
  flex: 1 1 500px;
  text-align: left;
}

.order-steps {
  list-style-position: inside;
  padding: 0;
  counter-reset: step-counter;
}

.order-steps li {
  font-size: 1.2rem;
  color: #000000;
  line-height: 1.6;
  margin-bottom: 20px;
  position: relative;
  padding-left: 30px;
}

.order-steps li::before {
  counter-increment: step-counter;
  content: counter(step-counter) ".";
  position: absolute;
  left: 0;
  top: 0;
  font-weight: bold;
  color: #DB463F;
  font-size: 1.2rem;
}

.order-image {
  flex: 1 1 400px;
  text-align: center;
}

.order-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Responsive Design for "How to Order" Section */
@media (max-width: 768px) {
  .products-page{
    
  }
  .how-to-order {
    padding: 40px 40px;
    text-align: left;
  }

  .how-to-order h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }

  .order-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  .order-text {
    text-align: left;
    flex: 1 1 100%;
  }

  .order-image {
    text-align: left;
    flex: 1 1 100%;
  }

  .order-steps li {
    padding-left: 20px;
  }

  .order-steps li::before {
    font-size: 0.9rem;
  }
}
