/* src/styles/MeetTheBaker/JourneyTimeline.css */

:root {
  --primary-color: #ffffff;
  --secondary-color: #ffffff;
  --background-color: #E7D6BA;
  --text-color: #555555;
  --heading-color: #333333;
  --border-radius: 8px;
  --box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.journey-timeline {
  padding: 50px 20px;
  background-color: var(--background-color);
}

.journey-timeline h2 {
  text-align: center;
  color: black;
  margin-bottom: 40px;
  font-size: 2.5rem;
  position: relative;
}

.timeline-container {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  padding: 10px 0;
}

.timeline-container::after {
  content: '';
  position: absolute;
  width: 4px;
  background-color: var(--primary-color);
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -2px;
}

.timeline-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  position: relative;
}

.timeline-image {
  position: relative;
  width: 300px;
  height: 300px;
  border-radius: 30%;
  overflow: hidden;
  border: 3px solid var(--primary-color);
  box-shadow: var(--box-shadow);
}

.timeline-item.left .timeline-content {
  order: 1;
  margin-right: 20px;
  max-width: 45%;
}

.timeline-item.left .timeline-image {
  order: 2;
  margin-left: 20px;
}

.timeline-item.right .timeline-content {
  order: 2;
  margin-left: 20px;
  max-width: 45%;
}

.timeline-item.right .timeline-image {
  order: 1;
  margin-right: 20px;
}

.timeline-content {
  background-color: var(--secondary-color);
  padding: 20px 25px;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  transition: transform 0.3s, box-shadow 0.3s;
}

.timeline-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


@media screen and (max-width: 900px) {
  .timeline-image{
    width: 400px
  }
  .timeline-content{
    max-width: 50%;
    margin-right: 5%;
  }
  
  .timeline-container::after{
    visibility: hidden;
  }
}

/* Responsive Styles for Small Screens (600px and below) */
@media screen and (max-width: 600px) {
  .timeline-item {
    flex-direction: row; /* Keep row direction */
    align-items: center;
    justify-content: flex-start;
    text-align: left;
  }
  .timeline-container::after{
    visibility: hidden;
  }
  .timeline-content {
    max-width: 60%;
    padding: 15px 20px;
  }

  .timeline-image {
    width: 200px;
    height: 200px;
    margin-left: 10px;
  }

  .timeline-content h4 {
    font-size: 1.3rem;
  }

  .timeline-content p {
    font-size: 0.9rem;
  }
}