/* src/styles/MeetTheBaker/SocialMediaSection.css */

.social-media-section {
  /* Scoped CSS Variables */
  --social-primary-color: #ffffff; /* Saddle Brown */
  --social-background-color: #771111; /* White */
  --social-text-color: #ffffff; /* Dark Gray */
  --social-overlay-color: rgba(255, 255, 255, 0.1); /* Semi-transparent overlay */
  --social-border-radius: 50%;
  --social-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  --social-transition-speed: 0.3s;
  --social-icon-color: #8b4513; /* Default icon color */
  
  padding: 80px 20px;
  background-color: var(--social-background-color);
  text-align: center;
}

.social-media-section h2 {
  color: var(--social-primary-color);
  margin-bottom: 30px;
  font-size: 2.5rem;
  position: relative;
}

.social-media-section h2::after {
  content: '';
  width: 250px;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.479);
  display: block;
  margin: 10px auto 0;
  border-radius: 2px;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 7vh;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.social-icon-link {
  color: var(--social-icon-color);
  background-color: var(--social-overlay-color);
  padding: 20px;
  scale: 1.4;
  border-radius: var(--social-border-radius);
  transition: transform var(--social-transition-speed), background-color var(--social-transition-speed), color var(--social-transition-speed);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--social-box-shadow);
}

.social-icon-link:hover {
  background-color: black;
  color: #ffffff;
  transform: translateY(-5px) scale(1.1);
}

.social-icon-link:focus {
  outline: 2px solid var(--social-primary-color);
  outline-offset: 4px;
}

.social-message {
  color: var(--social-text-color);
  font-size: 1.2rem;
  font-family:'Times New Roman', Times, serif;
  max-width: 600px;
  margin: 0 auto;
}

/* Icon-specific Colors */
.social-icon-link[aria-label="Instagram"] {
  --social-icon-color: #E1306C;
}

.social-icon-link[aria-label="Facebook"] {
  --social-icon-color: #3b5998;
}

.social-icon-link[aria-label="Twitter"] {
  --social-icon-color: #1DA1F2;
}

.social-icon-link[aria-label="Pinterest"] {
  --social-icon-color: #E60023;
}

.social-icon-link[aria-label="YouTube"] {
  --social-icon-color: #FF0000;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .social-icons {
    gap: 40px;
  }
  
  .social-icon-link {
    padding: 12px;
  }
  
  .social-message {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .social-icons {
    flex-wrap: wrap;
    gap: 25px;
  }
  
  .social-icon-link {
    padding: 10px;
    width: 50px;
    height: 50px;
  }
  
  .social-message {
    font-size: 0.9rem;
  }
}