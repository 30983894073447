/* src/styles/InstagramFeed.css */

/* Instagram Section Container */
.instagram-section {
    padding: 60px 20px;
    background-color: #E7D6BA; /* Light beige */
    text-align: center;
  }
  
  /* Section Title */
  .instagram-title {
    font-size: 3rem;
    font-weight: bold;
    color: #8b4513;
    margin-bottom: 40px;
  }
  
  /* Instagram Posts Container */
  .instagram-posts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 100px;
  }
  
  /* Instagram Media */
  .instagram-media {
    width: 300px;
    max-width: 100%;
  }

  /* Responsive Design */
@media (max-width: 768px) {
  .instagram-section {
    padding: 40px 10px;
  }

  .instagram-title {
    font-size: 2rem; /* Further reduce font size */
    margin-bottom: 20px;
  }

  .instagram-posts {
    gap: 20px; /* Reduce gap for smaller screens */
  }

  .instagram-media {
    max-width: 220px; /* Reduce size for tablets */
  }
}

/* Extra small devices (phones, less than 480px) */
@media (max-width: 480px) {
  .instagram-section {
    padding: 30px 5px;
  }

  .instagram-title {
    font-size: 1.8rem;
  }

  .instagram-media {
    max-width: 180px; /* Reduce size for smaller screens */
  }
}