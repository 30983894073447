/* src/styles/Carousel.css */

/* =========================================
   CSS Variables for Consistent Theming
========================================= */
:root {
  /* Color Palette */
  --color-primary: #DB463F;
  --color-secondary: #A82424;
  --color-accent: #771111;
  --color-background: #E7D6BA;
  --color-text: #302B28;
  --color-light: #FFFFFF;
  --color-dark: #000000;

  /* Font Sizes */
  --font-size-h2: 2.5rem;
  --font-size-h3: 1.5rem;
  --font-size-p: 1rem;
  --font-size-description: 0.9rem;
  --font-size-button: 1rem;

  /* Spacing */
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 2rem;
}

/* =========================================
   Carousel Container Styling
========================================= */
.carousel-container {
  width: 100%;
  padding: var(--spacing-lg);
  padding-bottom: 12vh;
  box-shadow: 0px 0px 2px 5px rgba(0, 0, 0, 0.144);
  backdrop-filter: blur(10px); /* Blur effect for background */
  margin: 0 auto;
  text-align: center;
  position: relative;

  /* Inherit the background from the parent */
  background: inherit; /* This ensures the background is inherited */
  background-attachment: fixed; /* Maintain the fixed background */
  z-index: 2; /* Ensure the carousel appears above other elements */
}

/* =========================================
   Carousel Heading Styling
========================================= */
.carousel-container h2 {
  font-size: 3rem;
  margin-top: var(--spacing-lg);
  padding-bottom: var(--spacing-sm);
  border-bottom: 2px solid var(--color-light);
  text-shadow: 2px 2px 2px black;
  margin-bottom: 2rem;
  font-weight: 900;
  letter-spacing: 10px;
  color: var(--color-light);
}

/* =========================================
   Slick Carousel Dots Styling
========================================= */
/* Position the dots below the carousel */
.slick-dots {
  bottom: -55px;
  white-space: nowrap; /* Prevent wrapping */
  overflow: hidden; /* Hide overflow if any */
}

/* Spacing between individual dots */
.slick-dots li {
  margin: 0 3px; /* Increased spacing between dots */
}

/* Inactive dot styling */
.slick-dots li button:before {
  color: var(--color-light);
  opacity: 0.3;
  font-size: 8px;
}

/* Active dot styling */
.slick-dots li.slick-active button:before {
  color: var(--color-primary);
  opacity: 0.8;
  font-size: 11px;
}

/* Prevent dots from wrapping */
.slick-dots {
  white-space: nowrap;
  overflow: hidden;
}

/* =========================================
   Carousel Items Wrapper
========================================= */
.carousel {
  /* Remove display: flex to prevent conflicts with React Slick */
  /* display: flex; */
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 0;
}

/* =========================================
   Carousel Items Grid
========================================= */
/* Remove the carousel-items class if not used */
/*.carousel-items {
  display: flex;
  gap: 100px; 
  justify-content: space-between;
}*/

/* =========================================
   Carousel Item Styling
========================================= */
.carousel-item {
  background-color: #302B28;
  border: 2px solid black;
  border-radius: 20px;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  /* Constrain height */
  max-height: 600px; /* Adjust as needed */
  overflow: hidden; /* Hide overflowing content */
}

.carousel-item:hover {
  transform: translateY(-5px); /* Slight lift on hover */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

/* =========================================
   Carousel Item Image Styling
========================================= */
.carousel-item img {
  width: 80%; /* Adjust width as needed */
  height: auto; /* Maintain aspect ratio */
  max-height: 300px; /* Constrain image height */
  object-fit: cover;
  border-radius: 15px;
  margin-bottom: var(--spacing-sm);
  display: block;
  margin-left: auto;
  margin-right: auto; /* Center the image horizontally */
}

/* =========================================
   Item Information Wrapper
========================================= */
.item-info {
  text-align: center;
  width: 100%; /* Ensure content spans full width */
}

/* =========================================
   Item Name Styling
========================================= */
.item-info h3 {
  font-size: 2.0rem;
  margin-bottom: var(--spacing-sm);
  color: #E7D6BA;
}

/* =========================================
   Item Price Styling
========================================= */
.item-info p.price {
  font-size: 1.7rem;
  color: white;
  margin-bottom: var(--spacing-sm);
}

/* =========================================
   Item Description Styling
========================================= */
.item-info p.description {
  font-size: 1rem;
  color: #E7D6BA;
  margin-bottom: var(--spacing-md);
}

/* =========================================
   Add to Cart Button Styling
========================================= */
.add-to-cart-button {
  padding: 8px 16px;
  background-color: var(--color-primary);
  color: var(--color-light);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: var(--font-size-button);
  transition: background-color 0.3s ease;
}

.add-to-cart-button:hover {
  background-color: var(--color-secondary); /* Darker shade on hover */
}

/* =========================================
   Navigation Buttons Styling
========================================= */
.nav-button {
  background-color: transparent;
  border: none;
  font-size: 1.5em;
  margin: 0 10px;
  color: var(--color-primary);
  cursor: pointer;
  transition: color 0.3s ease;
}

.nav-button:hover {
  color: var(--color-secondary); /* Darker shade on hover */
}

.slick-slide {
  padding: 0 40px;
}

.slick-list {
  /* Remove margin to prevent extra height */
  margin: 0; 
  overflow: hidden;
}

/* =========================================
   Responsive Styles
========================================= */

/* Tablet and Small Desktop */
@media (max-width: 1024px) {
  .carousel-container h2 {
    font-size: 2rem; /* Smaller heading on tablets */
  }

  /* Adjust carousel-item width if needed */
  .carousel-item {
    max-height: 800px; /* Reduce max-height */
  }

  .carousel-item img {
    width: 70%; /* Adjust width as needed */
    max-height: 100px; /* Adjust image height */
  }

  .item-info h3 {
    font-size: 1.5rem; /* Smaller item name */
  }

  .item-info p.price {
    font-size: 1.2rem; /* Smaller price text */
  }

  .item-info p.description {
    font-size: 0.85rem; /* Smaller description text */
  }

  .add-to-cart-button {
    font-size: 0.9rem; /* Smaller button text */
    padding: 6px 12px; /* Reduced padding */
  }

  .nav-button {
    font-size: 1.2em; /* Smaller navigation buttons */
    margin: 0 5px; /* Reduced margin */
  }
}

/* Mobile Devices */
@media (max-width: 768px) {
  /* Hide Slick Dots on Mobile */
  .slick-dots {
    display: none;
  }

  .carousel-container {
    padding: var(--spacing-md);
    padding-bottom: 10%;
    background: #000;
  }

  .carousel-item {
    max-height: 700px; /* Further reduce max-height */
    padding: 10px;
    padding: 10%;
  }

  .carousel-item img {
    width: 80%; /* Larger images on mobile */
    max-height: 160px; /* Adjust image height */
  }

  .item-info h3 {
    font-size: 1.2rem; /* Smaller item name */
  }

  .item-info p.price {
    font-size: 1rem; /* Smaller price text */
  }

  .item-info p.description {
    font-size: 0.8rem; /* Smaller description text */
  }

  .add-to-cart-button {
    font-size: 0.8rem;
    padding: 5px 10px;
  }

  .nav-button {
    font-size: 1em;
    margin: 0 3px;
  }
}

/* Extra Small Mobile Devices */
@media (max-width: 480px) {
  .carousel-container h2 {
    font-size: 1.5rem; /* Further reduced heading size */
  }

  .carousel-item {
    max-height: 550px; /* Almost full-width cards */
  }

  .carousel-item img {
    width: 80%; /* Maintain image size */
    max-height: 140px; /* Adjust height */
  }

  .item-info h3 {
    font-size: 1rem; /* Smaller item name */
  }

  .item-info p.price {
    font-size: 0.9rem; /* Smaller price text */
  }

  .item-info p.description {
    font-size: 0.8rem; /* Smaller description text */
  }

  .add-to-cart-button {
    font-size: 0.8rem;
    padding: 5px 10px;
  }

  .nav-button {
    font-size: 1em;
    margin: 0 3px;
  }
}
