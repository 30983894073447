/* src/styles/ShippingProcess.css */

.shipping-process {
    background-color: #302B28;
    padding: 60px 20px;
    margin-top: 5%;
    text-align: center;
  }
  
  .shipping-title {
    font-size: 2.5rem;
    color: #ffffff;
    margin-bottom: 40px;
  }
  
  .steps-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .step {
    flex: 1 1 30%;
    max-width: 45%;
    margin: 0 10px;
    text-align: center;
  }
  
  .step-icon {
    font-size: 4rem;
    color: #FFD7F0;
    margin-bottom: 20px;
  }
  
  .step-title {
    font-size: 1.5rem;
    color: #FFD7F0;
    margin-bottom: 10px;
  }
  
  .step-description {
    font-size: 1rem;
    color: #ffffff;
  }
  
  @media (max-width: 768px) {
    .steps-container {
      flex-direction: column;
    }
  
    .step {
      max-width: 100%;
      margin-bottom: 40px;
    }
  }
  