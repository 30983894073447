/* src/styles/THC/THCAboutSection.css */

.thc-about-section {
    padding: 50px 20px;
    background-color: #302B28;
  }
  
  .thc-about-content {
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    gap: 40px;
    flex-wrap: wrap;
  }
  
  .thc-about-image {
    flex: 1 1 3200px;
    max-width: 400px;
    border-radius: 10px;
    object-fit: cover;
    box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.562);
  }
  
  .thc-about-text {
    flex: 2 1 500px;
  }
  
  .thc-about-text h2 {
    color: white;
    margin-bottom: 20px;
  }
  
  .thc-about-text p {
    font-size: 1.3rem;
    color: white;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
/* src/styles/THC/THCAboutSection.css */

.thc-about-section {
  padding: 50px 20px;
  background-color: #302B28;
}

.thc-about-content {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  gap: 40px;
  flex-wrap: wrap;
}

.thc-about-image {
  flex: 1 1 320px; /* Changed from 3200px to 320px for consistency */
  max-width: 400px;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.562);
}

.thc-about-text {
  flex: 2 1 500px;
}

.thc-about-text h2 {
  color: white;
  margin-bottom: 20px;
}

.thc-about-text p {
  font-size: 1.3rem;
  color: white;
  line-height: 1.6;
  margin-bottom: 15px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .thc-about-content {
    flex-direction: column;
    text-align: center;
  }

  .thc-about-image {
    max-width: 80%; /* Limit the image size on smaller screens */
    width: auto;
    margin-bottom: 20px; /* Add spacing below the image */
  }

  .thc-about-text {
    flex: none;
  }
}

@media (max-width: 480px) {
  .thc-about-image {
    max-width: 100%; /* Allow the image to take full width on very small screens */
  }

  .thc-about-text p {
    font-size: 1.1rem; /* Reduce text size for better readability */
  }
}
