/* src/styles/MeetTheBaker/GallerySection.css */

.gallery-section {
  /* Scoped CSS Variables */
  --gallery-primary-color: #8b4513; /* Saddle Brown */
  --gallery-secondary-color: #ffffff; /* White */
  --gallery-background-color:  #302B28;; /* Light Brown */
  --gallery-text-color: #333333; /* Dark Gray */
  --gallery-overlay-color: rgba(139, 69, 19, 0.8); /* Semi-transparent Saddle Brown */
  --gallery-border-radius: 10px;
  --gallery-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --gallery-transition-speed: 0.3s;
  
  padding: 50px 20px;
  background-color: var(--gallery-background-color);
}

.gallery-section h2 {
  text-align: center;
  color: white;
  margin-bottom: 40px;
  font-size: 2.5rem;
  position: relative;
}

.gallery-section h2::after {
  content: '';
  width: 120px;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.493);
  display: block;
  margin: 10px auto 0;
  border-radius: 2px;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns for desktop */
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.gallery-item {
  position: relative;
  overflow: hidden;
  border-radius: var(--gallery-border-radius);
  box-shadow: var(--gallery-box-shadow);
  transition: transform var(--gallery-transition-speed), box-shadow var(--gallery-transition-speed);
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  transition: transform var(--gallery-transition-speed);
}

.gallery-item:hover img {
  transform: scale(1.05);
}

.gallery-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.555);
  opacity: 0;
  transition: opacity var(--gallery-transition-speed);
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery-item:hover .overlay {
  opacity: 1;
}

.overlay-text {
  color: whirte;
  font-size: 1.2rem;
  text-align: center;
  padding: 10px;
}

/* Responsive Styles */
@media (max-width: 992px) {
  .gallery-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for tablet */
  }
}

@media (max-width: 600px) {
  .gallery-grid {
    grid-template-columns: 1fr; /* 1 column for mobile */
  }

  .overlay-text {
    font-size: 1rem;
  }
}