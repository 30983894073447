/* src/styles/Footer.css */

/* Footer Styles */
.footer {
  flex-shrink: 0; /* Prevent the footer from shrinking */
  background-color: #333333;
  color: #f0e6d6;
  padding: 40px 20px 0 20px;
  font-family: 'Evenings', sans-serif;
}
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .footer-section {
    flex: 1 1 200px;
    margin: 20px;
  }
  
  .footer-logo {
    width: 150px;
    margin-bottom: 20px;
  }
  
  .footer-description {
    font-size: 0.9rem;
    line-height: 1.5;
  }
  
  .footer-nav h4,
  .veteran-support h4,
  .legal-links h4,
  .social-media h4 {
    font-size: 1.2rem;
    margin-bottom: 15px;
    color: #ffffff;
  }
  
  .footer-nav ul,
  .legal-links ul,
  .social-media ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-nav ul li,
  .legal-links ul li {
    margin-bottom: 10px;
  }
  
  .footer-nav ul li a,
  .legal-links ul li a {
    color: #f0e6d6;
    text-decoration: none;
    font-size: 0.9rem;
  }
  
  .footer-nav ul li a:hover,
  .legal-links ul li a:hover {
    text-decoration: underline;
  }
  
  .veteran-support img.veteran-badge {
    width: 250px;
    margin-bottom: 15px;
  }
  
  .veteran-support p {
    font-size: 0.9rem;
    line-height: 1.5;
  }
  
  .social-media ul {
    display: flex;
    list-style: none;
    padding: 0;
  }
  
  .social-media ul li {
    margin-right: 15px;
    font-size: 1.5rem;
    font-family: 'Times New Roman', Times, serif;
  }
  
  .social-media ul li a {
    color: #f0e6d6;
    font-size: 3.5rem;
  }
  
  .social-media ul li a:hover {
    color: #e7d6ba;
  }
  
  .footer-bottom {
    text-align: center;
    padding: 20px 0;
    margin-top: 20px;
    border-top: 1px solid #555555;
  }
  
  .footer-bottom p {
    margin: 0;
    font-size: 0.9rem;
  }
  
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-section {
      margin: 20px 0;
      text-align: center;
    }
  
    .footer-logo {
      margin: 0 auto 20px auto;
    }
  
    .social-media ul {
      justify-content: center;
    }
  }