/* src/styles/MeetTheBaker/HeroSection.css */

.hero-section-baker {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 70vh;
  overflow: hidden;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.158); /* Dark overlay */
  z-index: 1;
}

.hero-content {
  position: relative;
  z-index: 2;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.baker-image-wrapper {
  display: inline-block;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid rgba(255, 255, 255, 0.7);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}

.baker-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-title {
  font-size: 3rem;
  width: 70%;
  color: #fff;
  text-shadow: 2px 2px 4px #000;
  padding: 10px 20px;
  word-wrap: break-word; /* Ensures text breaks at the edge */
  overflow-wrap: break-word; /* Allows breaking inside words if necessary */
  text-align: center; /* Keeps the text centered */
  margin: 0 auto 30px; /* Center horizontally and add bottom margin */
}


.hero-nav {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.hero-button {
  padding: 10px 20px;
  background-color: #771111;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.hero-button:hover {
  background-color: #a0522d;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .hero-title {
    width: 45%;
    font-size: 2.5rem;
  }

  .baker-image-wrapper {
    width: 300px;
    height: 300px;
  }
}

@media (max-width: 768px) {
  .hero-title {
    width: 60%;
    font-size: 2rem;
  }

  .baker-image-wrapper {
    width: 250px;
    height: 250px;
  }
}

@media (max-width: 480px) {
  .hero-title {
    width: 80%;
    font-size: 1.5rem;
  }

  .baker-image-wrapper {
    width: 200px;
    height: 200px;
  }

  .hero-nav {
    flex-direction: column;
    gap: 10px;
  }
}
