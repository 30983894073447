/* src/styles/Products/SeasonalBanner.css */

.seasonal-banner {
  position: relative;
  width: 100%;
  height: 70vh;
  overflow: hidden;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Text styling for the banner */
.banner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(255, 115, 0);
  border: 2px solid;
  text-align: center;
  background-color: #000000;
  padding: 20px;
  border-radius: 10px;
  text-shadow: 0px 0px 3px rgba(255, 255, 255, 0);
}

/* Styling for the heading and paragraph */
.banner-text h2 {
  font-size: 4.5rem;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0);
  margin-bottom: 10px;
  white-space: nowrap; /* Prevent wrapping */
}

.banner-text .letter{
  display: inline-block;
  animation: bobbing 5s ease-in-out infinite;
}

.banner-text p {
  font-size: 1.2rem;
  z-index: 10;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .seasonal-banner{
    background-attachment: scroll; /* Prevent fixed behavior on mobile */
    background-size: cover; /* Ensure it still covers the area */
    background-position: center top; /* Align the image for better fit */
    max-height: 100vh; /* Maintain viewport height */
  }
  
  .banner-text{
    padding: 5%;
  }

  .banner-text h2 {
      font-size: 2rem;
  }

  .banner-text p {
      font-size: 1rem;
  }
}
