/* App.css - Remove the global font declaration from here */

*{
  font-family: 'garogier'; /* Add Arial for fallback */
}


body {
  margin: 0;
  background: black;
  overflow-x: hidden;
}


/* Keyframes for fade-in from left */
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Keyframes for fade-in from right */
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* When the 'in-view' class is added, trigger the animation */
.animate-on-scroll.in-view {
  opacity: 1;
  transform: translateY(0);
}

/* Optional: Use data attributes to apply different animations */
[data-animation="fade-in-left"].in-view {
  animation: fadeInLeft 1s forwards;
}

[data-animation="fade-in-right"].in-view {
  animation: fadeInRight 1s forwards;
}

/* Initial styles for animate-on-scroll elements */
.animate-on-scroll {
  opacity: 0;
  transform: translateY(20px); /* Adjust as needed */
  transition: opacity 1s ease-out, transform 1s ease-out;
}

/* src/styles/PageTransitions.css */

.transition-group {
  position: relative;
}

.fade-enter {
  opacity: 0;
  transform: translateX(100%);
  position: absolute;
  width: 100%;
}

.fade-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
}

.fade-exit {
  opacity: 1;
  transform: translateX(0%);
}

.fade-exit-active {
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
}