.product-card {
    background-color: #774F43;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    transition: transform 0.3s;
    position: relative;
    box-sizing: border-box; /* Includes padding and border in the total width */
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    z-index: 1;
  }
  
  
  .card-image-wrapper {
    position: relative;
  }
  
  .card-image-wrapper img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    transition: all 1.2s ease;
  }
  
  .product-info {
    padding: 20px;
    flex-grow: 1;
  }
  
  .product-info h3 {
    font-size: 1.5rem;
    color: white;
    margin-bottom: 10px;
  }
  
  .product-info p {
    font-size: 1rem;
    color: white;
    margin-bottom: 15px;
  }
  
  .price {
    font-size: 1.8rem;
    font-weight: bold;
    color: white;
    margin-bottom: 15px;
  }
  
  .size-selection,
  .flavor-selection,
  .quantity-selector {
    color: white;
    margin-bottom: 15px;
  }
  
  .size-selection label,
  .flavor-selection label,
  .quantity-selector label {
    display: block;
    margin-bottom: 5px;
  }
  
  .size-selection select,
  .flavor-selection select,
  .quantity-selector input {
    width: 100%;
    padding: 8px;
    border: 1px solid white;
    border-radius: 5px;
    color: white;
    background: black;
  }
  
  .filling-selection {
    margin-bottom: 15px;
  }
  
  .filling-selection label {
    display: block;
    margin-bottom: 5px;
    color: white;
  }
  
  .filling-selection select {
    width: 100%;
    padding: 8px;
    background: #000;
    border: 2px solid white;
    border-radius: 5px;
    color: white;
  }
  
  .buttercream-selection {
    color: white;
    margin-bottom: 15px;
  }
  
  .buttercream-selection label {
    display: block;
    margin-bottom: 5px;
    color: white;
  }
  
  .buttercream-selection select {
    width: 100%;
    padding: 8px;
    border: 1px solid white; /* Match the border style of other selects */
    border-radius: 5px;
    color: white;
    background: black;
  }
  
  .add-to-cart-button {
    padding: 10px 20px;
    background-color: white;
    color: #ff0000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .add-to-cart-button:hover {
    background-color: #a0522d;
  }
  
  .request-quote-button {
    padding: 10px 20px;
    background-color: #a0522d;
    color: #fff;
    border: none;
    margin-left: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .request-quote-button:hover {
    background-color: #8b4513;
  }
  
  /* For Tablets and Smaller Devices */
@media (max-width: 768px) {

  .product-info {
    padding: 15px;
  }

  .product-info h3 {
    font-size: 1.3rem;
  }

  .product-info p {
    font-size: 0.9rem;
  }

  .price {
    font-size: 1.5rem;
  }

  .size-selection select,
  .flavor-selection select,
  .quantity-selector input,
  .filling-selection select,
  .buttercream-selection select {
    padding: 6px;
  }

  .add-to-cart-button,
  .request-quote-button {
    width: 100%;
    padding: 8px 0;
    font-size: 0.9rem;
    margin-left: 0;
  }

  .request-quote-button {
    margin-top: 5px;
  }

  /* Make filter sections collapsible or move them to the bottom */
  .filter-item {
    margin-bottom: 20px;
  }
}

/* For Mobile Devices */
@media (max-width: 480px) {

  .card-image-wrapper img {
    height: 150px;
  }

  .product-info h3 {
    font-size: 1.2rem;
  }

  .product-info p {
    font-size: 0.8rem;
  }

  .price {
    font-size: 1.2rem;
  }

  .add-to-cart-button,
  .request-quote-button {
    font-size: 0.8rem;
    padding: 6px;
    width: 100%;
  }

  /* Filter handling for smaller screens */
  .filters {
    max-width: 100%;
    margin: 0 auto;
    padding: 10px;
    border-radius: 8px;
    background-color: #E7D6BA;
    position: relative;
  }

  .filters h4 {
    font-size: 1.2rem;
  }
  
  /* Add an option to collapse filters if they're too long */
  .filters.collapsible {
    max-height: 200px; /* Adjustable height */
    overflow-y: auto;
  }
}