/* src/styles/THC/AgeVerificationModal.css */

.age-verification-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(48, 43, 40, 0.8); /* Semi-transparent dark background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .age-verification-modal {
    background-color: var(--color-light);
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    max-width: 400px;
    width: 90%;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  }
  
  .age-verification-modal h2 {
    color: var(--color-primary);
    margin-bottom: 20px;
  }
  
  .age-verification-modal p {
    color: var(--color-text);
    font-size: 1.1rem;
    margin-bottom: 30px;
  }
  
  .age-verification-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .age-button {
    padding: 10px 25px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
  }
  
  .yes-button {
    background-color: var(--color-primary);
    color: var(--color-light);
  }
  
  .yes-button:hover {
    background-color: var(--color-secondary);
  }
  
  .no-button {
    background-color: var(--color-accent);
    color: var(--color-light);
  }
  
  .no-button:hover {
    background-color: var(--color-secondary);
  }