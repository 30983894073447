/* src/styles/Products/QuoteForm.css */

.quote-form-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000; /* Ensure it's above other elements */
}

.quote-form-container {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  position: relative;
  overflow-y: auto;
  width: 90%;
  height: 90%;
  border: 10px solid black;
  max-width: 500px;
  z-index: 1000;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

.close-button {
  position: absolute;
  top: 15px;
  right: 20px;
  background: none; /* No background */
  border: none;
  font-size: 1.5rem; /* 24px */
  cursor: pointer;
  z-index: 1001; /* Above the container */
  color: #333;
}

.close-button:hover {
  color: #DB463F; /* Highlight color on hover */
}

.quote-form-header {
  text-align: center;
  margin-bottom: 20px;
}

.quote-form-header h2 {
  margin-bottom: 10px;
  font-size: 28px;
  color: #000000; /* Consistent color */
}

.quote-form-header p {
  font-size: 18px;
  color: #000000;
}

.quote-form {
  display: flex;
  flex-direction: column;
}

.quote-form h3{
  color: black;
  padding-top: 5vh;
}

.quote-form label {
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: bold;
  color: black;
}

.quote-form input,
.quote-form select,
.quote-form textarea {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.quote-form textarea {
  resize: vertical;
  min-height: 80px;
}

.quote-form button[type="submit"] {
  margin-top: 20px;
  padding: 10px;
  background-color: #DB463F;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.quote-form button[type="submit"]:hover {
  background-color: #a82424;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .quote-form-container {
    padding: 20px;
  }

  .quote-form input,
  .quote-form select,
  .quote-form textarea {
    font-size: 14px;
  }

  .quote-form button[type="submit"] {
    font-size: 16px;
  }
}