/* src/styles/News/NewsModal.module.css */

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modalContent {
    background: #fff;
    padding: 30px;
    border-radius: 10px;
    max-width: 600px;
    width: 90%;
    text-align: left;
    position: relative;
    z-index: 1000;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 15px;
    background-color: #000000; /* Add a background color */
    border: none;
    color: #db463f; /* Use a noticeable color */
    font-size: 1.8rem; /* Slightly adjust the font size */
    font-weight: bold;
    cursor: pointer;
    z-index: 1001; /* Ensure it's above other content */
    border-radius: 50%; /* Make the button circular */
    padding: 5px; /* Add padding for better visibility */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  }
  
  .newsList {
    margin-top: 20px;
  }
  
  .newsItem {
    margin-bottom: 20px;
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .newsItem:hover {
    background-color: #f0f0f0;
  }
  
  .backButton {
    background: none;
    border: none;
    color: #db463f;
    font-size: 1rem;
    cursor: pointer;
    margin-bottom: 15px;
  }
  
  .selectedNewsImages {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
  }
  
  .newsImage {
    width: calc(50% - 5px);
    border-radius: 5px;
  }
  