/* src/styles/Success.css */

.success-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - var(--navbar-height));
    background-color: #fff5e6; /* Light beige */
    padding: 20px;
  }
  
  .success-card {
    background-color: #ffffff;
    border: 2px solid #e8c3a0; /* Light beige border */
    border-radius: 10px;
    padding: 40px;
    max-width: 600px;
    text-align: center;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  }
  
  .success-card h1 {
    font-size: 2.5rem;
    color: #4d3319; /* Dark brown */
    margin-bottom: 20px;
  }
  
  .success-card p {
    font-size: 1.2rem;
    color: #666666;
    margin-bottom: 20px;
  }
  
  .home-button {
    display: inline-block;
    padding: 15px 30px;
    background-color: #8b4513; /* Dark brown */
    color: #ffffff;
    text-decoration: none;
    border-radius: 8px;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .home-button:hover {
    background-color: #a0522d; /* Slightly lighter brown */
  }
  