/* src/styles/THC/THCProductList.css */

.thc-product-list {
    padding: 50px 20px;
    background-color: var(--color-background);
  }
  
  .thc-product-list h2 {
    text-align: center;
    color: black;
    font-size: 3.0rem;
    margin-bottom: 40px;
    text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.486);
  }
  
  .thc-products-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 30px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .thc-product-card {
    background-color: #774F43;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 3px 6px rgba(0,0,0,0.1);
  }
  
  .thc-product-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.2);
  }
  
  .thc-product-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .thc-product-name {
    font-size: 1.6rem;
    margin: 25px 0 20px;
    color: white;
  }
  
  .thc-product-description {
    font-size: 1.1rem;
    color: #E7D6BA;
    margin-bottom: 15px;
  }
  
  .thc-product-price {
    font-size: 1.4rem;
    color: #E7D6BA;
    margin-bottom: 20px;
  }
  
  .add-to-cart-button {
    padding: 10px 20px;
    background-color: #771111;
    color: var(--color-light);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    bottom: 0;
    transition: background-color 0.3s;
  }
  
  .add-to-cart-button:hover {
    background-color: var(--color-secondary);
  }
  
  /* Responsive Styles */
  @media (max-width: 600px) {
    .thc-products-grid {
      grid-template-columns: 1fr;
    }
  }