/* src/styles/Cart.css */

/* Cart Header */
.cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px;
  background-color: #e8c3a0; /* Light beige */
  color: #4d3319; /* Dark brown */
  font-size: 1.5rem;
  font-weight: bold;
  border-bottom: 2px solid #d2a679; /* Slightly darker beige */
}

/* Close Cart Button */
.close-cart-button {
  background: none;
  border: none;
  font-size: 2rem; /* Increased font size for better visibility */
  color: #4d3319;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-cart-button:hover {
  color: #d9534f; /* Change color on hover to indicate interactivity */
}

/* Cart Items Container */
.cart-items {
  max-height: calc(100vh - 300px); /* Adjusted based on header and footer */
  overflow-y: auto;
}

/* Cart Item */
.cart-item {
  display: flex;
  padding: 15px 25px;
  border-bottom: 1px solid #ddd;
  align-items: center;
}

.cart-item img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.cart-item-details {
  flex: 1;
}

.cart-item-details p {
  margin: 5px 0;
  color: #333;
  font-size: 1rem;
}

.item-name {
  font-weight: bold;
  font-size: 1.1rem;
}

.cart-controls {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.cart-controls input {
  width: 50px;
  margin-right: 10px;
  text-align: center;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.cart-controls input:focus {
  border-color: #8b4513; /* Dark brown on focus */
  outline: none;
}

.cart-controls .item-total {
  margin-right: auto;
  font-weight: bold;
  color: #4d3319;
  font-size: 1rem;
}

.cart-controls button {
  background-color: transparent;
  border: none;
  color: #d9534f; /* Red color for remove button */
  cursor: pointer;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.cart-controls button:hover {
  color: #c9302c;
}

/* Total Price */
.total-price {
  font-weight: bold;
  padding: 15px 25px;
  font-size: 1.2rem;
  text-align: right;
  border-top: 1px solid #ddd;
  color: #333;
}

/* Buttons */
.checkout-button,
.clear-cart-button {
  width: calc(100% - 50px);
  margin: 15px 25px 15px 25px;
  padding: 15px;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.checkout-button {
  background-color: #5cb85c; /* Green */
}

.checkout-button:hover {
  background-color: #4cae4c;
}

.clear-cart-button {
  background-color: #d9534f; /* Red */
}

.clear-cart-button:hover {
  background-color: #c9302c;
}

/* Cart Dropdown */
.cart-dropdown {
  position: fixed;
  top: 8%; /* Ensure this matches your navbar height */
  right: 0;
  width: 25vw;
  max-width: 400px;
  height: 100vh;
  background-color: #fff;
  overflow-y: auto;
  z-index: 1000;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  box-shadow: -4px 0 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
}

.cart-dropdown.active {
  transform: translateX(0);
  opacity: 1;
}

/* Cart Overlay */
.cart-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Empty Cart Message */
.cart-empty {
  padding: 50px 20px;
  text-align: center;
  font-size: 1.2rem;
  color: #777;
}

/* Responsive Design */
@media (max-width: 768px) {
  .cart-dropdown {
    width: 80vw;
  }

  .cart-header {
    padding: 15px 20px;
    font-size: 1.3rem;
  }

  .close-cart-button {
    font-size: 1.8rem;
  }

  .cart-item {
    padding: 10px 15px;
  }

  .cart-item img {
    width: 60px;
    height: 60px;
    margin-right: 15px;
  }

  .checkout-button,
  .clear-cart-button {
    margin: 10px 15px 10px 15px;
    padding: 12px;
    font-size: 0.9rem;
  }
}
