/* src/styles/ReviewSection.css */

/* Review Section Container */
.review-section {
    padding: 50px 20px;
    background-color: #771111; /* Light beige to match the bakery theme */
    background-size: cover;
    background-position: center;
    text-align: center;
  }
  
  /* Section Title */
  .review-title {
    font-size: 3rem;
    font-weight: bold;
    color: #ffffff; /* SaddleBrown color */
    text-shadow: 1px 1px 2px #fff;
  }
  
  .see-here{
    color: white;
    font-size: 1.2rem;
  }

  /* Review Cards Container */
  .review-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    
  }
  
  /* Individual Review Card */
  .review-card {
    background-color: #fdf6e4 !important;
    backdrop-filter: blur(5px);
    width: 300px;
    padding: 20px;
    border-radius: 15px;
    position: relative;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.644);
    border: black 2px solid;
  }
  
  /* Quote Icon */
  .quote-icon {
    font-size: 50px;
    color: #8b4513;
    position: absolute;
    top: -20px;
    left: 20px;
  }
  
  /* Review Text */
  .review-text {
    font-size: 1.2rem;
    color: #333;
    margin-top: 30px;
    font-style: italic;
  }
  
  /* Review Author */
  .review-author {
    display: block;
    margin-top: 20px;
    font-size: 1rem;
    color: #555;
    font-weight: bold;
  }
  
/* Responsive Design */
@media (max-width: 768px) {
  .review-section {
    padding: 30px 10px; /* Reduce padding further for mobile */
  }

  .review-title {
    font-size: 2rem;
  }

  .review-cards {
    flex-direction: column;
    align-items: center;
  }

  .review-card {
    width: 90%; /* Make it take most of the screen width */
    max-width: 350px;
  }

  .quote-icon {
    font-size: 35px;
  }

  .review-text {
    font-size: 0.95rem;
  }
}

/* Extra small devices (phones, less than 480px) */
@media (max-width: 480px) {
  .review-section {
    padding: 20px 5px; /* Reduce padding further for smaller devices */
  }

  .review-title {
    font-size: 1.8rem;
  }

  .review-card {
    width: 100%;
    max-width: 300px; /* Further restrict max width for smaller screens */
  }

  .quote-icon {
    font-size: 30px;
  }

  .review-text {
    font-size: 0.9rem;
  }

  .review-author {
    font-size: 0.85rem;
  }
}