/* src/styles/THC/THCHeroSection.css */

.thc-hero-section {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    background-size: cover;
    background-position: center;
    overflow: hidden;
  }
  
  .thc-hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(48, 43, 40, 0.5); /* Semi-transparent dark overlay */
    z-index: 1;
  }
  
  .thc-hero-content {
    position: relative;
    z-index: 2;
    text-align: center;
    color: var(--color-light);
    padding: 20px;
  }
  
  .thc-hero-title {
    font-size: 3rem;
    margin-bottom: 20px;
    color: var(--color-light);
    text-shadow: 2px 2px 4px rgba(0,0,0,0.7);
  }
  
  .thc-hero-description {
    font-size: 1.2rem;
    max-width: 600px;
    margin: 0 auto;
    color: var(--color-light);
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .thc-hero-title {
      font-size: 2.5rem;
    }
  
    .thc-hero-description {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .thc-hero-title {
      font-size: 2rem;
    }
  
    .thc-hero-description {
      font-size: 0.9rem;
    }
  }