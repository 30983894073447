/* src/styles/Navbar.css */

/* General Styles for Navbar */
.navbar {
  background-color: #000000ec; /* Semi-transparent background */
  color: #E7D6BA;
  width: 100%;
  z-index: 9999;
  position: absolute; /* Fix the navbar to the top of the page */
  top: 0;
  left: 0;
  backdrop-filter: blur(10px); /* Add backdrop blur */
  -webkit-backdrop-filter: blur(10px); /* For Safari support */
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}

/* Logo on the Left */
.navbar-logo {
  font-size: 32px;
  font-weight: bold;
  flex: 1;
  position: absolute;
  margin-left: 12vw;
}

.navbar-logo img {
  height: 120px; /* Adjust the height to fit the navbar */
  width: auto;
  display: block;
}

/* Navigation Links */
.navbar-links {
  display: flex;
  justify-content: flex-end;
  flex: 2; /* Allow links to take more space */
  gap: 34px;
  list-style: none;
  margin: 0;
  margin-right: 2vw;
  padding: 0;
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.navbar-links li {
  position: relative; /* For dropdown positioning */
}

.navbar-links li a,
.navbar-links li button {
  text-decoration: none;
  color: white;
  font-size: 20px;
  font-weight: lighter;
  transition: color 0.3s ease;
  background: none;
  border: none;
  cursor: pointer;
}

.navbar-links li a:hover,
.navbar-links li button:hover {
  color: #DB463F;
}

/* Active Link Styling */
.navbar-links li a.active {
  color: #DB463F;
}

/* Dropdown Styles */
.navbar-dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
}

.navbar-dropdown .caret-icon {
  margin-left: 5px;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: calc(100% + 0px); /* Position below the parent with a small gap */
  left: 0;
  background-color: #000000ec; /* Same as navbar background */
  padding: 0;
  list-style: none;
  min-width: 150px;
  border-radius: 4px;
  z-index: 1000;
}

.dropdown-menu li {
  width: 100%;
}

.dropdown-menu li a {
  display: block;
  padding: 10px 0;
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: lighter;
  text-decoration: none;
  transition: color 0.3s ease;
}

.dropdown-menu li a:hover {
  color: #DB463F;
}

/* Show the dropdown on hover */
.navbar-dropdown:hover .dropdown-menu {
  display: block;
}

/* Hamburger Menu Button */
.navbar-hamburger {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
  outline: none;
  margin-left: auto;
}

/* Cart Icon */
.cart-icon {
  position: relative;
  margin-left: 20px;
}

.cart-count {
  position: absolute;
  top: -8px;
  right: -20px;
  background-color: red;
  color: white;
  font-size: 1.00rem;
  padding: 2px 7px;
  border-radius: 50%;
}

/* Floating Cart Icon */
.floating-cart-icon {
  position: fixed;
  top: 20px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
  background-color: #e8c3a0; /* Light beige or your preferred color */
  padding: 15px;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  color: #4d3319; /* Dark brown for icon color */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out;
}

.floating-cart-icon .cart-count {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #d9534f; /* Red color for the badge */
  color: #fff;
  font-size: 0.8rem;
  border-radius: 50%;
  padding: 4px 6px;
}

/* Position when cart is active */
.floating-cart-icon.cart-active {
  transform: translateY(100vh); /* Moves the icon out of view */
  opacity: 0;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .navbar-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: #302B28;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 100;
  }

  .navbar-open {
    display: flex;
  }

  .navbar-logo {
    margin-top: 6%;
    margin-left: 5vw;
  }

  .navbar-hamburger {
    display: block;
  }

  /* Mobile Dropdown Styles */
  .navbar-dropdown .dropdown-toggle {
    width: 100%;
    text-align: left;
  }

  .dropdown-menu {
    position: static;
    background-color: transparent;
    padding: 0;
    margin: 0;
    box-shadow: none;
  }

  .dropdown-menu li a {
    padding: 10px 0;
    color: white;
    font-size: 20px;
    font-weight: lighter;
  }

  .dropdown-menu li a:hover {
    color: #DB463F;
  }
}
