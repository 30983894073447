/* src/styles/LegalPages.css */

.legal-page {
    max-width: 800px;
    margin: 60px auto;
    padding: 0 20px;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .legal-page h1 {
    font-size: 2.5rem;
    color: #DB463F;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .legal-page h2 {
    font-size: 1.75rem;
    color: #DB463F;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  
  .legal-page p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 15px;
  }