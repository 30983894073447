/* src/styles/ConfirmationModal.css */

/* Modal overlay */
.confirmation-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100; /* Ensure it's above other elements */
  }
  
  /* Modal container */
  .confirmation-modal-container {
    background-color: #fff;
    padding: 30px;
    width: 80%;
    max-width: 500px;
    border-radius: 8px;
    position: relative;
    text-align: center;
  }
  
  /* Close button */
  .confirmation-modal-container .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 28px;
    cursor: pointer;
  }
  
  /* Heading */
  .confirmation-modal-container h2 {
    margin-bottom: 20px;
    color: #af4c4c;
  }
  
  /* Paragraph */
  .confirmation-modal-container p {
    font-size: 18px;
    color: #555;
    margin-bottom: 30px;
  }
  
  /* OK button */
  .confirmation-modal-container .ok-button {
    padding: 12px 24px;
    background-color: #e91e63;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
  }
  
  .confirmation-modal-container .ok-button:hover {
    background-color: #d81b60;
  }