/* src/styles/News/News.module.css */

.newsSection {
    padding: 40px;
    background-color: #E7D6BA; /* Corrected rgba syntax */
    backdrop-filter: blur(8px);
    text-align: center;
}

.sectionTitle {
    font-size: 3.5rem;
    color: #000000;
    margin-bottom: 20px;
}

.newsGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto auto auto;
    gap: 15px;
    margin-top: 20px;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
}

.newsDescription {
    grid-column: 1 / 2;
    grid-row: 1 / 4;
    text-align: left;
    padding: 20px;
    background: #771111;
    color: white;
    border-radius: 10px;
}

.newsItem1,
.newsItem2,
.newsItem4 {
    position: relative;
    overflow: hidden;
    height: 250px; /* Consistent height for items 1, 2, and 4 */
}

.newsItem3 {
    grid-column: 2 / 5;
    grid-row: 2 / 3;
    position: relative;
    overflow: hidden;
    height: 600px;
}

.newsItem4 {
    grid-column: 4 / 5; /* Adjust according to your layout */
    grid-row: 1 / 2; /* Adjust according to your layout */
    height: 250px; /* Match other items or adjust as needed */
}

.newsItem {
    border-radius: 10px;
    overflow: hidden;
}

.newsImageContainer {
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 20px 10px black;
    border-radius: 8px; /* Optional: Add border-radius for a rounded effect */
    overflow: hidden; /* Ensures the image doesn't overflow the container */
}

.newsImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.newsImage:hover {
    transform: scale(1.05); /* Optional: Slight zoom on hover */
}

.newsOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.171);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.newsItem:hover .newsOverlay {
    opacity: 1;
}

.newsContent {
    color: #fff;
    text-align: center;
}

.readMoreButton,
.viewAllButton {
    background: #db463f;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

/* Modal Styles */

.imageModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.imageModal.open {
    opacity: 1;
    visibility: visible;
}

.imageModalContent {
    position: relative;
    max-width: 90vw; /* Ensures modal content doesn't exceed viewport width */
    max-height: 90vh; /* Ensures modal content doesn't exceed viewport height */
    cursor: default; /* Prevent cursor from changing to pointer when over content */
    animation: fadeIn 0.3s ease;
}

.imageModalContent img {
    width: 100%;
    height: auto;
    object-fit: contain; /* Ensures the image maintains aspect ratio */
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    display: block;
    margin: 0 auto;
}

/* Specific styling for Image 3 when expanded */
.image3 img {
    max-width: 90vw; /* Reduces the size to 90% of viewport width */
    max-height: 90vh; /* Reduces the size to 90% of viewport height */
}

.closeButton {
    position: absolute;
    top: -15px; /* Positioned relative to modal content */
    right: -15px; /* Positioned relative to modal content */
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    border: none;
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 50%;
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes fadeIn {
    from {
        transform: scale(0.8);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

/* Existing Responsive Design */
@media (max-width: 768px) {
    .newsGrid {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto auto auto;
    }

    .newsItem1,
    .newsItem2,
    .newsItem3,
    .newsItem4 {
        height: 200px; /* Adjust heights for smaller screens */
    }

    .imageModalContent {
        max-width: 95vw;
        max-height: 95vh;
    }
}

@media (max-width: 480px) {
    .newsGrid {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto auto auto;
    }

    .newsItem1,
    .newsItem2,
    .newsItem3,
    .newsItem4 {
        height: 150px; /* Further adjust heights for very small screens */
    }
}

/* **Additional Enhancements for Improved Responsiveness** */

/* Medium Devices (tablets) */
@media (min-width: 481px) and (max-width: 768px) {
    .sectionTitle {
        font-size: 3rem;
    }

    .newsDescription {
        padding: 15px;
        font-size: 0.9rem;
    }

    .newsItem3 {
        height: 400px; /* Reduce height for tablets */
    }

    .newsItem1,
    .newsItem2,
    .newsItem4 {
        height: 200px;
    }
}

/* Large Devices (desktops) */
@media (min-width: 1601px) {
    .newsGrid {
        grid-template-columns: repeat(5, 1fr); /* Add an extra column for very large screens */
    }

    .newsItem3 {
        height: 700px; /* Increase height for larger screens */
    }

    .newsItem1,
    .newsItem2,
    .newsItem4 {
        height: 300px; /* Increase height for larger screens */
    }

    .sectionTitle {
        font-size: 5rem;
    }
}

/* Extra Small Devices (very small mobile devices) */
@media (max-width: 320px) {
    .sectionTitle {
        font-size: 2.5rem;
    }

    .newsDescription {
        padding: 10px;
        font-size: 0.8rem;
    }

    .newsGrid {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto auto auto;
    }

    .newsItem1,
    .newsItem2,
    .newsItem3,
    .newsItem4 {
        height: 120px; /* Further reduce height for very small screens */
    }
}

/* Flexible Grid Layout for Dynamic Content */
.newsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Allows dynamic number of columns based on screen size */
    grid-auto-rows: minmax(150px, auto); /* Flexible row heights */
    gap: 15px;
    margin-top: 20px;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
}

/* Adjust newsDescription positioning for different screen sizes */
@media (max-width: 768px) {
    .newsDescription {
        grid-column: 1 / -1; /* Span all columns on smaller screens */
        grid-row: 1 / 2;
        margin-bottom: 20px;
    }

    .newsItem3 {
        grid-column: 1 / -1; /* Span all columns on smaller screens */
        grid-row: 2 / 3;
        height: 300px;
    }

    /* Adjust other items accordingly */
    .newsItem1,
    .newsItem2,
    .newsItem4 {
        grid-column: span 1;
        grid-row: auto;
        height: 200px;
    }
}

@media (max-width: 480px) {
    .newsDescription {
        padding: 10px;
        font-size: 0.8rem;
    }

    .newsGrid {
        grid-template-columns: 1fr;
    }

    .newsItem3 {
        height: 250px;
    }

    .newsItem1,
    .newsItem2,
    .newsItem4 {
        height: 150px;
    }
}
